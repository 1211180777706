1
<!-- The icon/data combination items that appear in the Lens Selection section of New Patient and Patient Detail pages. -->
<template>
    <b-row class="lens-selection-data-item">
        <b-col class="py-2">
            <div class="my-3 d-flex">
                <div class="label align-self-center w-140px">{{ t('surgeryDate') }}:</div>
                <v-date-picker
                    :popover="{
                        positionFixed: true,
                        visibility: disabled || !canReadPersonalData ? 'hidden' : 'hover-focus',
                    }"
                    v-model="surgeryDate"
                    :masks="{input: ['YYYY-MM-DD']}"
                    :locale="dateCurrentLanguage"
                    :update-on-input="false"
                    class="mr-2"
                >
                    <template v-slot="{inputValue, inputEvents}">
                        <input
                            id="surgery-date"
                            class="form-control"
                            :disabled="disabled || !canReadPersonalData"
                            :value="inputValue"
                            v-on="inputEvents"
                            required
                            :placeholder="t('dateHint')"
                        />
                    </template>
                </v-date-picker>

                <div class="label mr-2 align-self-center w-140px">{{ t('serialNum') }}:</div>
                <div>
                    <b-form-input
                        type="text"
                        :disabled="disabled || !canReadPersonalData"
                        v-model="serialNumber"
                        class="form-control"
                    ></b-form-input>
                </div>

                <div hidden>
                    <div class="label mt-2 mb-1">{{ t('surgeryTime') }}</div>
                    <b-form-timepicker
                        reset-button
                        size="sm"
                        hide-header
                        label-no-time-selected=""
                        :disabled="disabled || !canReadPersonalData"
                        v-model="surgeryTime"
                        v-bind="timepickerLabels || {}"
                        placeholder="HH:MM"
                        class="text-left"
                    ></b-form-timepicker>
                </div>
            </div>
            <b-table
                class="table-lens-list table-borderless"
                :items="displayedLenses"
                :fields="lensFields"
                thead-class="default-header-style"
                sticky-header="220px"
                fixed
            >
                <template #table-colgroup="scope">
                    <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{width: colWidths[field.key].min}"
                    />
                </template>

                <template #head(model)>
                    <div
                        v-if="opEye == EyeSides.OD"
                        class="header-box left d-flex align-items-center pl-2 pt-1"
                    >
                        <img src="@/assets/eye_icon_white.svg" />
                        <img src="@/assets/eye_icon_gray_orig.svg" />
                    </div>
                    <div v-else class="header-box left"></div>
                </template>

                <template #head(status)>
                    <div class="header-box middle d-flex align-items-center">
                        <span class="text-subheader heavy text-white">{{ opEye }}</span>
                    </div>
                </template>

                <template #head(surgeon)>
                    <div
                        v-if="opEye == EyeSides.OS"
                        class="header-box right d-flex align-items-center justify-content-end pr-2 pt-1"
                    >
                        <img src="@/assets/eye_icon_gray_orig.svg" />
                        <img src="@/assets/eye_icon_white.svg" />
                    </div>
                    <div v-else class="header-box right"></div>
                </template>

                <template #cell(model)="{item}">
                    <div class="content">
                        <LensModel
                            :lensDescriptionDetails="item.reservedLensDescription"
                            normalTextClasses="text-caption heavy text-black mb-0"
                            highlightTextClasses="text-caption text-red mb-0"
                        />
                        <LensPrescription
                            :lensDescriptionDetails="item.reservedLensDescription"
                            normalTextClasses="font-weight-light"
                            highlightTextClasses="font-weight-light text-red"
                        />
                    </div>
                </template>

                <template #cell(status)="{item, value}">
                    <div class="content">
                        <p class="text-caption heavy text-black mb-0">
                            {{
                                getReservationOrderStatus(value, {
                                    isConsignment: isConsignment(item),
                                })
                            }}
                            -
                            <custom-router-link
                                :to="{
                                    name: pageName[value],
                                    params: {
                                        orderId: item.orderHeaderId,
                                    },
                                }"
                            >
                                {{ !!item.orderNumber ? item.orderNumber : item.orderHeaderId }}
                            </custom-router-link>
                        </p>
                        <span
                            v-if="isConsignment(item) && item.serial"
                            class="text-caption light text-gray-dark mb-0"
                            :title="item.serial"
                        >
                            {{ t('invLookup_Consignment') }}
                            {{ item.serial }}
                        </span>
                    </div>
                </template>

                <template #cell(surgeon)="{item}">
                    <div class="content">
                        {{
                            surgeonIdNameFormat(
                                item.contactPartyNumber,
                                item.doctorName,
                                item.doctorActive
                            )
                        }}
                    </div>
                </template>
            </b-table>
        </b-col>
    </b-row>
</template>

<script>
import set from 'lodash/set';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import {mapGetters} from 'vuex';
import {surgeonIdNameFormat, getReservationOrderStatus} from '@/utilities/formatters';
import {OrderSubStatus, PageName} from '@/constants/order';
import {EyeSides} from '@/store/modules/preopdata';
import {InventorySource} from '@/constants/inventory';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';
import LensPrescription from '@/components/LensPrescription';
import LensModel from '@/components/LensModel';

export default {
    name: 'LensDataItem',
    components: {
        LensModel,
        LensPrescription,
    },
    data() {
        return {
            EyeSides,
            colWidths: {
                model: {min: '140px'},
                status: {min: '170px'},
                surgeon: {min: '175px'},
            },
        };
    },
    props: {
        lenses: {
            type: Array,
        },
        opEye: {
            type: String,
            default: '',
        },
        disabled: {
            default: false,
        },
    },
    computed: {
        ...mapGetters('user', ['currentUser']),

        ...mapGetters('permissions', ['permissions']),
        ...mapGetters('patient', ['currentPatient', 'currentPatientSurgeryDateTimeData']),

        canReadPersonalData() {
            return this.checkPermissions({
                [PERMISSIONS.PATIENT_PERSONAL_DATA]: PERMISSIONS_VALUES.READ_WRITE,
            });
        },
        displayedLenses() {
            return this.lenses.filter(
                (l) => !l.followUpOrderHeaderId && l.status !== OrderSubStatus.CANCELLED
            );
        },
        lensFields() {
            return [
                {key: 'model', label: this.t('odLens'), tdClass: 'np'},
                {key: 'status', label: this.t('status'), tdClass: 'np'},
                {key: 'surgeon', label: this.t('surgeon'), tdClass: 'np'},
            ];
        },
        timepickerLabels() {
            return {
                labelResetButton: this.t('timePicker_labelResetButton'),
                labelCloseButton: this.t('timePicker_labelCloseButton'),
                labelAm: this.t('timePicker_labelAm'),
                labelPm: this.t('timePicker_labelPm'),
            };
        },
        pageName() {
            return PageName;
        },

        dateCurrentLanguage: function () {
            if (this.currentLanguageCode) return this.currentLanguageCode;

            return 'en';
        },
        surgeryDate: {
            get() {
                return this.getSurgeryDates('SurgeryDate');
            },
            async set(newValue) {
                await this.setSurgeryDates('SurgeryDate', newValue || null);
            },
        },
        surgeryTime: {
            get() {
                return this.getSurgeryDates('SurgeryTime');
            },
            async set(newValue) {
                await this.setSurgeryDates('SurgeryTime', newValue);
            },
        },
        serialNumber: {
            get() {
                return get(this.currentPatient, `${this.opEye.toLowerCase()}LensSerial`, '');
            },
            async set(newValue) {
                set(this.currentPatient, `${this.opEye.toLowerCase()}LensSerial`, newValue);
                await this.setSerialNumber(newValue);
            },
        },
    },
    methods: {
        surgeonIdNameFormat,
        getReservationOrderStatus,
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        getSurgeryDates(fieldType) {
            return get(
                this.currentPatientSurgeryDateTimeData,
                `${this.opEye.toLowerCase()}${fieldType}`,
                ''
            );
        },
        isConsignment(lens) {
            return lens.lensSourceId == InventorySource.CONSIGNMENT;
        },
        setSurgeryDates: debounce(async function (fieldType, newValue) {
            let dateTimeObj = this.currentPatientSurgeryDateTimeData;

            dateTimeObj = set(
                dateTimeObj || {},
                `${this.opEye.toLowerCase()}${fieldType}`,
                newValue
            );

            await this.$store.dispatch('patient/updatePatientSurgeryDateTimeData', {
                patientId: this.currentPatient.patientId,
                dateTimeObj: dateTimeObj,
            });
        }, 1000),
        setSerialNumber: debounce(async function (newValue) {
            await this.$store.dispatch('patient/updatePatientLensSerialData', {
                patientId: this.currentPatient.patientId,
                opEye: this.opEye,
                lensSerial: newValue,
            });
        }, 1000),
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/variables';
@import '@/assets/css/mixins';

.b-form-timepicker > label {
    padding: 3px 0px 0px 4px;
    color: black;
}

.w-140px {
    width: 140px;
}

::v-deep .table-lens-list {
    th {
        padding: 0px;
        font-size: 1rem;
        font-weight: 600;
    }

    .np {
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .header-box {
        background: $gray-darker;
        height: 32px;
        width: 100%;
        margin-bottom: 6px;

        &.middle {
            padding-left: 43px;
        }

        &.left {
            border-radius: 4px 0 0 4px;
            margin-left: 1px;
        }

        &.right {
            border-radius: 0 4px 4px 0;
            width: calc(100% - 1px);
        }

        &.left-right {
            border-radius: 4px;
        }
    }
}
</style>
